<template>
  <div class="job-title-form">
    <fm-title title-text="岗位信息"></fm-title>
      <div class="job-title-item">
        <div class="hj">岗位类型</div>
        <div class="mc">岗位等级</div>
        <div class="cz"></div>
    </div>
    <div class="job-title-item" v-for="(item, index) in dataList.filter(v => !v.isDel)" :key="index">
      <div class="hj">
        <fm-select style="width:100%" size="norm" absolute filterable :clearable="true" v-model="item.type">
          <fm-option v-for="v in ['管理岗位', '专业技术岗位', '工勤技能岗位']" :key="v" :value="v" :label="v"></fm-option>
        </fm-select>
      </div>
      <div class="hj">
        <fm-select style="width:100%" size="norm" absolute filterable :clearable="true" v-model="item.level">
          <fm-option v-for="v in getLevelList(item.type)" :key="v" :value="v" :label="v"></fm-option>
        </fm-select>
      </div>
      <div class="cz"><fm-btn size="small" @click="del(index)">删除</fm-btn></div>
    </div>
    <div>
      <fm-btn @click="add" size="small">添加</fm-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    countWorkerJobTitleList: Array,
  },
  data () {
    return {
      dataList: []
    }
  },
  watch: {
    countWorkerJobTitleList: {
      handler () {
        let data = []
        if (this.countWorkerJobTitleList && this.countWorkerJobTitleList.length > 0) {
          data = this.countWorkerJobTitleList.map((v) => Object.assign({}, v))
        }
        this.dataList = data
      },
      immediate: true,
      deep: true
    },
    dataList: {
      handler () {
        let data = this.dataList.map(v => Object.assign({}, v, {
          id: String(v.id).includes('id_') ? null : v.id
        }))
        this.$emit('dataChange', data)
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    check () {
      let pass = true
      let dataMap = {}
      let i = 0
      while (i < this.dataList.length) {
        let v = this.dataList[i]
        if (!v.type) {
          this.$notice.info({
            title: '系统提示',
            desc: '岗位类型不能为空。'
          })
          pass = false
          break
        }
        if (!v.level) {
          this.$notice.info({
            title: '系统提示',
            desc: '岗位等级不能为空。'
          })
          pass = false
          break
        }
        if (dataMap[v.type]) {
          this.$notice.info({
            title: '系统提示',
            desc: v.type + '重复，每种岗位类型最多只能有一条岗位信息。'
          })
          pass = false
          break
        } else {
          dataMap[v.type] = true
        }
        if (!this.getLevelList(v.type).includes(v.level)) {
          this.$notice.info({
            title: '系统提示',
            desc: '岗位类型' + v.type + '和' + v.level + '不匹配'
          })
          pass = false
          break
        }
        i += 1
      }
      return pass
    },
    getLevelList (type) {
      let data = []
      if (type === '管理岗位') {
        data = ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十']
      } else if (type === '专业技术岗位') {
        data = ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十', '十一', '十二', '十三']
      } else if (type === '工勤技能岗位') {
        data = ['一', '二', '三', '四', '五', '普通工']
      }
      return data
    },
    del (index) {
      if (String(this.dataList[index].id).includes('id_')) {
        this.dataList[index] = null
        this.dataList = this.dataList.filter(v => v)
      } else {
        this.dataList[index].isDel = true
      }
    },
    add () {
      this.dataList.push({
        id: 'id_' + new Date().getTime(),
        type: '',
        level: ''
      })
    }
  }
}
</script>

<style lang="less" scoped>
.job-title-form {
  padding: 0 1rem;
}
.job-title-item {
  display: flex;
  align-items: center;
  margin: 1rem 0;
  .hj {
    width: 253px;
    margin-right: 10px;
  }
  .mc {
    width: 253px;
    margin-right: 10px;
  }
  .cz {
    width: 70px;
  }
}
</style>